<template>
  <div>
    <RoleHeader :data="roleHeader"></RoleHeader>
    <MenuNav :data="menuNav"></MenuNav>
  </div>
</template>

<script>
import RoleHeader from "../../components/RoleHeader";
import MenuNav from "../../components/MenuNav";
export default {
  components: { RoleHeader, MenuNav },
  data() {
    return {
      roleHeader: {
        background: require("../../assets/images/enterprise/title.png"),
        rightButtons: [
          {
            img: require("../../assets/images/enterprise/task.png"),
            imgChecked: require("../../assets/images/enterprise/task-checked.png"),
            link: "/enterprise/task",
          },
          {
            img: require("../../assets/images/enterprise/integral.png"),
            imgChecked: require("../../assets/images/enterprise/integral-checked.png"),
            link: "/enterprise/integral",
          },
          {
            img: require("../../assets/images/enterprise/discounts-header.png"),
            imgChecked: require("../../assets/images/enterprise/discounts-header-checked.png"),
            link: "/enterprise/discounts_header",
          },
        ],
      },
      menuNav: {
        navList: [
          {
            link: "/enterprise/recommend",
            img: require("../../assets/images/enterprise/recommended.png"),
            imgChecked: require("../../assets/images/enterprise/recommended-checked.png"),
          },
          {
            link: "/enterprise/test",
            img: require("../../assets/images/enterprise/test.png"),
            imgChecked: require("../../assets/images/enterprise/test-checked.png"),
          },
          {
            link: "/enterprise/cooperation",
            img: require("../../assets/images/enterprise/cooperation.png"),
            imgChecked: require("../../assets/images/enterprise/cooperation-checked.png"),
          },
          {
            link: "/enterprise/discounts",
            img: require("../../assets/images/enterprise/discounts.png"),
            imgChecked: require("../../assets/images/enterprise/discounts-checked.png"),
          },
          {
            link: "/enterprise/communication",
            img: require("../../assets/images/enterprise/communication.png"),
            imgChecked: require("../../assets/images/enterprise/communication-checked.png"),
          },
          {
            link: "/enterprise/advice",
            img: require("../../assets/images/enterprise/advice.png"),
            imgChecked: require("../../assets/images/enterprise/advice-checked.png"),
          },
        ],
      },
    };
  },
  methods: {
  },
};
</script>
